import React from 'react';
import { useLoaderData, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import { cheerfulFiestaPalette as mangoFusionPalette } from '@mui/x-charts/colorPalettes';

import { AnalyticsData, IntegrationColor } from '../components/user.jsx';
import { theme } from '../utils/theme.jsx';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function BarChartHours(data, valueFormat) {
  /* data format
   * data.data = Array with size 12 - one entry for each month
   * data.data[x] = Object with{} key: value: 'year':number of hours
   * data.valueFormat = Array with size 'number of years'
   * data.valueFormat[x] = Array with size 12 - one entry for each month (x=year)
   * data.valueFormat[x][y] = Accumulated hours month 'y' in year 'x'
   */

  let series = [];

  let firstYear = 0;

  if (!data || !data[0]) {
    console.error('Invalid data provided to BarChartHours');
    return null;
  }

  for (const year in data[0]) {
    if (year === 'month') continue;
    firstYear = firstYear === 0 ? year : firstYear;
    const yearIndex = year - firstYear;

    const valueFormatterHours = (v, { dataIndex }) => {
      if (v === null || v === undefined) return 'No data';
      
      let diff = '';
      const thisYear = valueFormat[yearIndex]?.[dataIndex] || 0;
      const prevYear = year > firstYear ? valueFormat[yearIndex - 1]?.[dataIndex] || 0 : 0;
      
      if (prevYear > 0) {
        const improvement = (thisYear / prevYear - 1) * 100;
        diff = ` - Increase(YOY): ${improvement.toFixed(0)}%`;
      }

      return `Month: ${v.toFixed(0)} - Year: ${thisYear.toFixed(0)}${diff}`;
    };

    for (let month = 0; month < 12; month++) {
      if (data[month]) {
        data[month]['month'] = months[month];
      }
    }

    series.push({ dataKey: year, label: year, valueFormatter: valueFormatterHours });
  }

  const chartSetting = {
    yAxis: [
      {
        label: 'reported hours',
      },
    ],
    height: 500,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-15px, 0)',
      },
    },
  };

  return (
    <BarChart
      dataset={data}
      xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
      margin={{ left: 60, top: 80 }}
      series={series}
      grid={{ horizontal: true }}
      colors={mangoFusionPalette}
      {...chartSetting}
    />
  );
}

function BarChartHoursLayout(data, valueFormat) {
  return (
    <>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={12} sm={8}>
        <p className="analytics-heading">Hours</p>
        <p className="analytics-text">
          Hours reported on articles/cost-centers tied to employees (hours reported by sub-consultants are excluded).
        </p>
        {BarChartHours(data, valueFormat)}
      </Grid>
      <Grid item xs={0} sm={2}></Grid>
    </>
  );
}

function BarChartEmployees(data, series) {
  const chartSetting = {
    yAxis: [
      {
        label: 'active employees',
      },
    ],
    height: 500,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-15px, 0)',
      },
    },
  };

  const updatedSeries = series.map(item => ({
    ...item,
    valueFormatter: (value) => `${value.toFixed(0)} employees`
  }));

  return (
    <BarChart
      dataset={data}
      xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
      margin={{ left: 60, top: 80 }}
      series={updatedSeries}
      grid={{ horizontal: true }}
      colors={mangoFusionPalette}
      {...chartSetting}
    />
  );
}

function BarChartEmployeesLayout(data, series) {
  return (
    <>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={12} sm={8}>
        <p className="analytics-heading">Active employees</p>
        <p className="analytics-text">Number of employees that have reported time with code TID for a given month.</p>
        {BarChartEmployees(data, series)}
      </Grid>
      <Grid item xs={0} sm={2}></Grid>
    </>
  );
}

export function AnalyticsLayout() {
  const data = useLoaderData();

  const today = new Date();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const valueFormatter = (value) => `${value.toFixed(0)}`;

  let series = {};
  let dataset = {};
  let datasetf = {};
  let yearStats = {};
  let companies = [];
  let tabs = [];
  let years = new Set();

  for (const d of data) {
    for (let td of d.timedata) {
      years.add(td.year);
    }
  }

  const sortedYears = Array.from(years).sort();
  const yearOffset = sortedYears[0];

  for (const d of data) {
    const firstYear = d.timedata[0].year;
    companies.push({ name: d.name, firstYear: firstYear });
    tabs.push({ name: d.name, type: 'single' });
    series[d.name] = [];

    yearStats[d.name] = [];
    datasetf[d.name] = { valueFormat: [] };

    for (let td of d.timedata) {
      const year = td.year;

      let hours = 0;
      datasetf[d.name].valueFormat[year - firstYear] = [];
      for (let month = 0; month < 12; month++) {
        hours += td.data[month];
        datasetf[d.name].valueFormat[year - firstYear][month] = hours;
      }

      series[d.name].push({
        dataKey: td.year,
        label: td.year.toString(),
      });

      let avgEmployees = 0;
      for (const e of td.costcenters) {
        avgEmployees += e !== undefined ? e.length : 0;
      }

      const lastMonth = today.getFullYear() == td.year ? today.getMonth() - 1 : 11;

      avgEmployees = lastMonth >= 0 ? avgEmployees / (lastMonth + 1) : undefined;

      yearStats[d.name].push({
        year: td.year,
        hours: hours,
        averagerate: td.averagerate,
        activeEmployees: avgEmployees,
      });
    }

    let monthTimeData = [];
    let monthTimeDataf = [];
    let monthCCData = [];
    for (let month = 0; month < 12; month++) {
      monthTimeData[month] = {};
      monthTimeDataf[month] = {};
      monthCCData[month] = {};
      for (let td of d.timedata) {
        monthTimeData[month][td.year.toString()] = td.data[month];
        monthTimeDataf[month][td.year.toString()] = td.data[month];
        monthTimeData[month]['month'] = months[month];

        monthCCData[month][td.year.toString()] = td.costcenters[month].length;
        monthCCData[month]['month'] = months[month];
      }
    }

    dataset[d.name] = monthTimeData;
    datasetf[d.name].data = monthTimeDataf;
    datasetf[d.name].activeEmployees = monthCCData;
  }

  companies.sort((a, b) => a.name > b.name);
  tabs.sort((a, b) => a.name > b.name);

  // data.data = Array with size 12 - one entry for each month
  // data.data[x] = Object with key: value: 'year':number of hours

  // data.valueFormat = Array with size 'number of years'
  // data.valueFormat[x] = Array with size 12 - one entry for each month (x=year)
  // data.valueFormat[x][y] = Accumulated hours month 'y' in year 'x'

  if (companies.length > 1) {
    /* prepend 'Summary'*/
    tabs = [{ name: 'Summary', type: 'combined' }, ...tabs];

    datasetf['Summary'] = { data: [], activeEmployees: [] };
    for (let month = 0; month < 12; month++) {
      datasetf['Summary'].data[month] = {};
      datasetf['Summary'].activeEmployees[month] = {};
      datasetf['Summary'].activeEmployees[month]['month'] = months[month]; //todo remove

      for (let year of sortedYears) {
        datasetf['Summary'].data[month][year.toString()] = 0;
        datasetf['Summary'].activeEmployees[month][year.toString()] = 0;
        for (let companyIndex = 0; companyIndex < companies.length; companyIndex++) {
          const companyName = companies[companyIndex].name;
          if (year < companies.find((c) => c.name == companyName).firstYear) {
            continue;
          }

          datasetf['Summary'].data[month][year.toString()] += datasetf[companyName].data[month][year.toString()];
          datasetf['Summary'].activeEmployees[month][year.toString()] +=
            datasetf[companyName].activeEmployees[month][year.toString()];
        }
      }
    }

    datasetf['Summary'].valueFormat = [];
    for (let year of sortedYears) {
      let valueFormat = [];
      for (let month = 0; month < 12; month++) {
        valueFormat[month] = 0;

        for (let companyIndex = 0; companyIndex < companies.length; companyIndex++) {
          const companyName = companies[companyIndex].name;

          const firstYear = companies.find((c) => c.name == companyName).firstYear;
          const companyYearIndex = year - firstYear;
          if (companyYearIndex < 0) {
            continue;
          }

          valueFormat[month] += datasetf[companyName].valueFormat[companyYearIndex][month];
        }
      }
      datasetf['Summary'].valueFormat.push(valueFormat);
    }
  }

  let companiesHoursYearTotal = {};
  let yearCompanyHours = [];
  if (companies.length > 1) {
    series['Summary'] = [];

    for (const year of sortedYears) {
      series['Summary'].push({
        dataKey: year,
        label: year.toString(),
        valueFormatter,
      });

      for (let i = 0; i < data.length; i++) {
        yearCompanyHours[year - yearOffset] = {};
      }
    }

    dataset['Summary'] = [];
    for (let month = 0; month < 12; month++) {
      dataset['Summary'][month] = {};
      dataset['Summary'][month]['month'] = months[month];
      for (const year of sortedYears) {
        dataset['Summary'][month][year.toString()] = 0;
        for (const d of data) {
          const companyMonthlyHours = dataset[d.name][month][year.toString()];
          if (companyMonthlyHours) {
            dataset['Summary'][month][year.toString()] += companyMonthlyHours;

            yearCompanyHours[year - yearOffset][d.name] ??= 0; /* set to 0 if new */
            yearCompanyHours[year - yearOffset][d.name] += companyMonthlyHours;
          }
        }
      }
    }
  }

  let companyHoursDist = [];
  if (companies.length > 1) {
    for (const d of data) {
      let companyHours = [];
      for (const year of sortedYears) {
        companyHours.push(yearCompanyHours[year - yearOffset][d.name]);
      }
      companyHoursDist.push({
        data: companyHours,
        label: d.name,
        color: IntegrationColor(d.name),
        stack: 'total',
        valueFormatter: (v, { dataIndex }) => {
          const value = v ? v : 0;
          return `${value.toFixed(0)} - ${((value / companiesHoursYearTotal[(dataIndex + yearOffset).toString()]) * 100).toFixed(0)}% of total hours`;
        },
      });
    }

    for (const year of sortedYears) {
      for (const d of data) {
        if (year < companies.find((c) => c.name == d.name).firstYear) {
          continue;
        }

        companiesHoursYearTotal[year.toString()] ??= 0; /* set to 0 if new */
        companiesHoursYearTotal[year.toString()] += yearCompanyHours[year - yearOffset][d.name];
      }
    }
  }

  const [value, setValue] = React.useState(tabs[0].name);

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <ThemeProvider theme={theme}>
        <TabContext value={value}>
          <TabList onChange={handleChange}>
            {tabs.map((tab) => (
              <Tab label={tab.name} value={tab.name} key={tab.name} />
            ))}
          </TabList>
          {tabs.map((tab) => (
            <TabPanel value={tab.name} key={tab.name}>
              <div>
                {tab.type == 'single' && (
                  <Box my={4} alignItems="center" gap={4} p={2} sx={{ width: '100%', typography: 'body1' }}>
                    <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                      {BarChartHoursLayout(datasetf[tab.name].data, datasetf[tab.name].valueFormat)}
                      {BarChartEmployeesLayout(datasetf[tab.name].activeEmployees, series[tab.name])}
                      <Grid item xs={0} sm={2}></Grid>
                      <Grid item xs={12} sm={8}>
                        <TableContainer component={Paper}>
                          <Table size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Year</TableCell>
                                <TableCell align="right">Hours</TableCell>
                                <TableCell align="right">Hourly rate (avg)</TableCell>
                                <TableCell align="right">Active employees (avg)</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {yearStats[tab.name].map((row) => (
                                <TableRow
                                  key={row.year}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.year}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.hours.toLocaleString(undefined, {
                                      maximumFractionDigits: 0,
                                    })}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.averagerate.toLocaleString(undefined, {
                                      maximumFractionDigits: 0,
                                    })}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.activeEmployees !== undefined
                                      ? row.activeEmployees.toLocaleString(undefined, { maximumFractionDigits: 0 })
                                      : '-'}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Grid item xs={0} sm={2}></Grid>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {tab.type == 'combined' && (
                  <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {BarChartHoursLayout(datasetf[tab.name].data, datasetf[tab.name].valueFormat)}
                    {BarChartEmployeesLayout(datasetf[tab.name].activeEmployees, series[tab.name])}
                    <Grid item xs={0} sm={2}></Grid>
                    <Grid item xs={12} sm={8}>
                      <p className="analytics-heading">Hours - Company distribution</p>
                      <BarChart
                        height={500}
                        series={companyHoursDist}
                        grid={{ horizontal: true }}
                        xAxis={[
                          {
                            scaleType: 'band',
                            data: [...sortedYears],
                            valueFormatter: (code, context) =>
                              context.location === 'tooltip'
                                ? `${code} total hours: ${companiesHoursYearTotal[code].toFixed(0)}`
                                : `${code}`,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={0} sm={2}></Grid>
                  </Grid>
                )}
              </div>
            </TabPanel>
          ))}
        </TabContext>
      </ThemeProvider>
    </Box>
  );
}

export const analyticsLoader = async () => {
  let data = null;

  try {
    await AnalyticsData().then(function (data_) {
      data = data_;
    });
  } catch (error) {
    if (error.name === 'AuthenticationError') {
      return <Navigate to={'/login'} state={{ from: '/analytics' }} />;
    }
    throw error;
  }

  return data;
};
