import React from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';

import { Home } from './pages/Home.jsx';
import { LoginLayout, LoginAuthOk, loginAuthOkLoader, LogoutLayout } from './pages/Auth.jsx';
import { MyProfile, myProfileLoader } from './pages/MyProfile.jsx';
import { MyRequests } from './pages/MyRequests.jsx';
import { FortnoxLayout, fortnoxLoader } from './pages/Fortnox.jsx';
import { AnalyticsLayout, analyticsLoader } from './pages/Analytics.jsx';
import { BalanceAccount, balanceAccountLoader } from './pages/BalanceAccount.jsx';
import { ExpenseReports, expenseReportsLoader } from './pages/ExpenseReports.jsx';
import { Employees, employeesLoader } from './pages/Employees.jsx';
import { CostCenters, costCentersLoader } from './pages/CostCenters.jsx';
import { CostCenter } from './pages/CostCenter.jsx';
import { HealthcheckTransactions, healthcheckTransactionsLoader } from './pages/HealthcheckTransactions.jsx';
import { Shop } from './pages/Shop.jsx';
import { Orders } from './pages/Orders.jsx';
import { AddArticle } from './pages/AddArticle.jsx';
import { ArticleList } from './pages/ArticleList.jsx';
import ErrorPage from './pages/ErrorPage.jsx';
import Footer from './components/Footer.jsx';
import { ProtectedRoute } from './components/ProtectedRoute.jsx';
import { ROLES } from './utils/rbac.js';
import { Unauthorized } from './pages/Unauthorized.jsx';
import { MyOrders } from './pages/MyOrders.jsx';
import { RequestList } from './pages/RequestList.jsx';
import { SalaryCalculation, salaryCalculationLoader } from './pages/SalaryCalculation.jsx';
import './style.scss';
import Sidebar from './components/Sidebar.jsx';

const Layout = () => {
  return (
    <div className="app">
      <Sidebar />
      <div className="main-content">
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/profile',
        element: (
          <ProtectedRoute requiredRole={ROLES.USER}>
            <MyProfile />
          </ProtectedRoute>
        ),
        loader: myProfileLoader,
      },
      {
        path: '/salary-calculation',
        element: (
          <ProtectedRoute requiredRole={ROLES.USER}>
            <SalaryCalculation />
          </ProtectedRoute>
        ),
        loader: salaryCalculationLoader,
      },
      {
        path: '/requests',
        element: (
          <ProtectedRoute requiredRoles={(ROLES.USER)}>
            <MyRequests />
          </ProtectedRoute>
        ),
      },
      {
        path: '/balance-account',
        element: (
          <ProtectedRoute requiredRole={ROLES.USER}>
            <BalanceAccount />
          </ProtectedRoute>
        ),
        loader: balanceAccountLoader,
      },
      {
        path: '/expense-reports',
        element: (
          <ProtectedRoute requiredRole={ROLES.USER}>
            <ExpenseReports />
          </ProtectedRoute>
        ),
        loader: expenseReportsLoader,
      },
      {
        path: '/cost-centers',
        element: (
          <ProtectedRoute requiredRole={ROLES.ADMIN}>
            <Outlet />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <CostCenters />,
            loader: costCentersLoader,
          },
          {
            path: ':id',
            element: <CostCenter />,
          },
        ],
      },
      {
        path: '/employees',
        element: (
          <ProtectedRoute requiredRole={ROLES.ADMIN}>
            <Employees />
          </ProtectedRoute>
        ),
        loader: employeesLoader,
      },
      {
        path: '/fortnox',
        element: (
          <ProtectedRoute requiredRole={ROLES.SYS_ADMIN}>
            <FortnoxLayout />
          </ProtectedRoute>
        ),
        loader: fortnoxLoader,
      },
      {
        path: '/analytics',
        element: (
          <ProtectedRoute requiredRole={ROLES.ADMIN}>
            <AnalyticsLayout />
          </ProtectedRoute>
        ),
        loader: analyticsLoader,
      },
      {
        path: '/healthcheck-transactions',
        element: (
          <ProtectedRoute requiredRole={ROLES.SYS_ADMIN}>
            <HealthcheckTransactions />
          </ProtectedRoute>
        ),
        loader: healthcheckTransactionsLoader,
      },
      {
        path: '/shop',
        element: (
          <ProtectedRoute requiredRoles={(ROLES.USER, ROLES.SYS_ADMIN, ROLES.SHOPKEEPER)}>
            <Shop />
          </ProtectedRoute>
        ),
      },
      {
        path: '/shop/my-orders',
        element: (
          <ProtectedRoute requiredRoles={(ROLES.USER, ROLES.SYS_ADMIN, ROLES.SHOPKEEPER)}>
            <MyOrders />
          </ProtectedRoute>
        ),
      },
      {
        path: '/shop/add-article',
        element: (
          <ProtectedRoute requiredRoles={[ROLES.SYS_ADMIN, ROLES.SHOPKEEPER]}>
            <AddArticle />
          </ProtectedRoute>
        ),
      },
      {
        path: '/shop/article-list',
        element: (
          <ProtectedRoute requiredRoles={[ROLES.SYS_ADMIN, ROLES.SHOPKEEPER]}>
            <ArticleList />
          </ProtectedRoute>
        ),
      },
      {
        path: '/shop/orders',
        element: (
          <ProtectedRoute requiredRoles={[ROLES.SYS_ADMIN, ROLES.SHOPKEEPER]}>
            <Orders />
          </ProtectedRoute>
        ),
      },
      {
        path: '/request-list',
        element: (
          <ProtectedRoute requiredRole={ROLES.ADMIN}>
            <RequestList />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/login',
    element: <LoginLayout />,
  },
  {
    path: '/authenticated',
    element: <LoginAuthOk />,
    loader: loginAuthOkLoader,
  },
  {
    path: '/logout',
    element: <LogoutLayout />,
  },
  {
    path: '/unauthorized',
    element: <Unauthorized />,
  },
]);

function App() {
  return (
    <div className="app">
      <div className="container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
